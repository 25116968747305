import Vue from 'vue';
import { getSDK } from '@/api/system';
import $wxJs from '@/util/jweixin-module/lib/index.js';
import { getUserInfo } from '@/api/user';
import { GET_SHOP_ID } from '@/util/storage';
import { VUE_APP_API_URL, VUE_APP_SHARE_URL } from '@/config';
import { Toast } from 'vant';

export function wxShare(opts) {

  getSDK({}, {
    headers: { noErrorTip: true },
  }).then(res => {
    /*
    appId: "wx2421855c90e7bad5"
    invitationCode: "DAXoDz"
    nonceStr: "qSNeFvJeFNcjNtez"
    shopUrl: "http://shop.ytjixiang.cn/dh5"
    signature: "9d1b021b5b2867d2306c0c572e0eb5972b8d683f"
    timestamp: "1651132813"
     */
    let { appId, timestamp, nonceStr, signature, invitationCode, shopUrl } = res || {};
    if (!appId)
      return;

    // 分享地址拼接
    let redirectUrl = `${shopUrl}?${opts.link}&invitationCode=${invitationCode}`;
    redirectUrl = encodeURIComponent(redirectUrl);
    opts.link = `${shopUrl}/shop-mobile/oauth/login?redirectUrl=${redirectUrl}`;
    if (typeof opts.afterLinkCreated === 'function') {
      opts.afterLinkCreated(opts.link);
    }

    // 重新登录地址保存到sessionStorage
    let reLoginState = `state=${encodeURIComponent('/hrefToSchool')}`;
    let reLoginLink = `${shopUrl}?${reLoginState}&invitationCode=${invitationCode}`;
    reLoginLink = encodeURIComponent(reLoginLink);
    sessionStorage.setItem('pany_reLoginLink', `${shopUrl}/shop-mobile/oauth/login?redirectUrl=${reLoginLink}`);
    sessionStorage.setItem('pany_toShopLink', `${shopUrl}/shop-mobile/oauth/login?redirectUrl=${shopUrl}`);

    $wxJs.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId, // 必填，公众号的唯一标识
      timestamp, // 必填，生成签名的时间戳
      nonceStr, // 必填，生成签名的随机串
      signature, // 必填，签名
      jsApiList: [
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone',
      ] // 必填，需要使用的JS接口列表
    });

    $wxJs.ready(() => {
      $wxJs.checkJsApi({
        jsApiList: [
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
        ],
        success: (res) => {
          let {
            title = '', desc = '', link = '', imgUrl = '', type = '', dataUrl = '', success = () => {
            }
          } = opts || {};

          if (res.errMsg === 'checkJsApi:ok') {
            // title 分享标题
            // desc 分享描述
            // link 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            // imgUrl 分享图标
            // type 分享类型,music、video或link，不填默认为link
            // dataUrl 如果type是music或video，则要提供数据链接，默认为空
            $wxJs.onMenuShareAppMessage({
              title, desc, link, imgUrl, type, dataUrl, success
            });
            $wxJs.onMenuShareTimeline({
              title, link, imgUrl, success
            });
          } else {
            $wxJs.updateAppMessageShareData({
              title, desc, link, imgUrl, type, dataUrl, success
            });
            $wxJs.updateTimelineShareData({
              title, link, imgUrl, success
            });
          }
        }
      });
    });
  });
}

export function wxShareBySchool(success) {
  // let shareUrl = VUE_APP_SHARE_URL + '?';
  wxShare({
    title: '商学院客户端',
    desc: '欢迎您',
    link: `state=${encodeURIComponent('/hrefToSchool')}`,
    // imgUrl: imgUrl ? imgUrlBaseUrl + imgUrl : '',
    success: () => {
      Toast('分享成功');
      typeof success === 'function' && success();
    }
  });
}

export function wxShareByMission(mission, success, afterLinkCreated) {
  // let shareUrl = VUE_APP_SHARE_URL + '?';
  // let userInfo = {};
  //
  // let promise1 = getUserInfo().then(res => {
  //   userInfo = res || {};
  // });
  // Promise.all([promise1]).then(() => {
  // });

  let imgUrlBaseUrl = VUE_APP_API_URL;

  // 分享参数设置
  let urlParams = {
    shopId: GET_SHOP_ID(), // 商铺id
    contentId: mission.contentId,
    taskId: mission.taskId, // 分享taskId
    userTaskId: mission.userTaskId, // 分享userTaskId
    state: encodeURIComponent('/hrefToSchool'),
    path: `missionShareDetail`,
  };
  urlParams = Object.keys(urlParams)
    .map(key => `${key}=${urlParams[key]}`)
    .join('&');
  // shareUrl += urlParams;

  let { title, desc, coverImg } = mission || {};
  wxShare({
    title: title || '',
    desc: desc || '',
    link: urlParams,
    imgUrl: coverImg ? imgUrlBaseUrl + coverImg : '',
    success: () => {
      Toast('分享成功');
      typeof success === 'function' && success();
    },
    afterLinkCreated,
  });

}
