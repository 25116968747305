// 获取用户当前所需要进行任务流程
//[{courseId, evalId, contractId}, ...]
import { getUserContract } from '@/api/user';

export default function (todoKey = '') {
  return new Promise(resolve => {
    getUserContract().then(res => {
      res = Array.isArray(res) ? res : [];
      if (!res.length)
        return resolve();

      let { courseId, evalId, contractId } = res[0];
      let chain = res[0] || {};
      if (todoKey) {
        chain = {
          [todoKey]: chain[todoKey],
        };
      }

      // // 需要学习
      // if (chain.courseId)
      //   return resolve({
      //     path: '/courseDetail',
      //     query: { courseId, isFlowChain: true },
      //   });
      // // 需要考试
      // if (chain.evalId)
      //   return resolve({
      //     path: '/subjectDetail',
      //     query: { evalId, isFlowChain: true },
      //   });
      // // 需要签约
      // if (chain.contractId)
      //   return resolve({
      //     path: '/agreementDetail',
      //     query: { contractId, isFlowChain: true },
      //   });

      let query = null;
      // 需要学习
      if (chain.courseId)
        query = {
          path: '/courseDetail',
          query: { courseId, isFlowChain: true },
        };
      // 需要考试
      else if (chain.evalId)
        query = {
          path: '/subjectDetail',
          query: { evalId, isFlowChain: true },
        };
      // 需要签约
      else if (chain.contractId)
        query = {
          path: '/agreementDetail',
          query: { contractId, isFlowChain: true },
        };

      if (query)
        return resolve({
          path: '/Transit',
          query,
        })

      resolve();
    });
  });
}
