import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import homeHome from '@/store/modules/homeHome';
import homeCourse from '@/store/modules/homeCourse';
import homeMission from '@/store/modules/homeMission';
import missionDetailList from '@/store/modules/missionDetailList';

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    homeHome,
    homeCourse,
    homeMission,
    missionDetailList,
  }
})
