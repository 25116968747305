import request from '@/plugins/axios';

export function getTokenService(query) {
  return request({
    url: 'auth/oauth/token',
    method: 'get',
    headers: {
      notNeedToken: true, // 是否不需要token。默认需要。
      getAllResponse: true, // 获取接口参数，包含code、msg、data等等。默认只返回data，不包含code、msg。
    },
    params: query
  });
}

/**
 *获取微信sdk配置
 */
export function getSDK(params = {}, opts) {
  return request({
    url: 'school-mobile/hCenter/wxConfig',
    method: 'get',
    params,
    ...opts,
  });
}

/**
 * 字典
 */
export function getDictType(dictName = '') {
  return request({
    url: `admin/dict/type/${dictName}`,
    method: 'get',
    params: {},
  });
}

/**
 * 广告图
 */
export function getAdCarouselList(dictName = '') {
  return request({
    url: `school-mobile/hAdCarousel/list`,
    method: 'get',
    params: {},
  });
}
