import request from '@/plugins/axios';

/**
 *用户信息
 */
export function getUserInfo(params = {}) {
  return request({
    url: 'school-mobile/hUserInfo/getUserInfo',
    method: 'get',
    params
  });
}

/**
 * 用户任务流程信息
 * [{courseId, evalId, contractId}, ...]
 */
export function getUserContract(params = {}) {
  return request({
    url: 'school-mobile/hContractInfo/getUserContract',
    method: 'get',
    params
  });
}

/**
 * 是否签到
 */
export function getUserIsSign(params = {}) {
  return request({
    url: 'school-mobile/hUserSignList/isSign',
    method: 'get',
    params
  });
}

/**
 * 签到列表
 */
export function getUserSignList(params = {}) {
  return request({
    url: 'school-mobile/hUserSignList/signList',
    method: 'get',
    params
  });
}

/**
 * 签到
 */
export function userSignIn(data = {}) {
  return request({
    url: 'school-mobile/hUserSignList',
    method: 'post',
    data
  });
}

/**
 *获取佣金
 */
export function getUserBilling(params) {
  return request({
    url: `school-mobile/hCenter/fundFlowPage`,
    method: 'get',
    params,
  });
}

/**
 * 解锁推广金额收入详情
 */
export function getLockDetail(params) {
  return request({
    url: `shop-mobile/hCenter/lockDetail`,
    method: 'get',
    params,
  });
}
