import Vue from 'vue';
import VueRouter from 'vue-router';
import Boot from '@/views/boot';
import { wxShareBySchool } from '@/util/wxShare';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'boot',
    meta: { name: '商学院' },
    component: Boot
  },
  {
    path: '/home',
    name: 'home',
    meta: { name: '商学院' },
    redirect: '/home/home',
    component: () => import('@/views/home/index'),
    children: [
      {
        path: '/home/home',
        name: 'homeHome',
        meta: { keepAlive: true, name: '商学院' },
        component: () => import('@/views/home/home'),
      },
      {
        path: '/home/course',
        name: 'homeCourse',
        meta: { keepAlive: true, name: '课程' },
        component: () => import('@/views/course/index'),
      },
      {
        path: '/home/mission',
        name: 'homeMission',
        meta: { keepAlive: true, name: '任务' },
        component: () => import('@/views/mission/index'),
      },
      {
        path: '/home/my',
        name: 'homeMy',
        meta: { keepAlive: true, name: '我的' },
        component: () => import('@/views/my'),
      },
    ],
  },
  {
    path: '/courseDetail',
    name: 'courseDetail',
    meta: { name: '课程详情' },
    component: () => import('@/views/course/courseDetail'),
  },
  {
    path: '/subjectDetail',
    name: 'subjectDetail',
    meta: { name: '答题详情' },
    component: () => import('@/views/subject/subjectDetail'),
  },
  {
    path: '/agreementDetail',
    name: 'agreementDetail',
    meta: { name: '协议详情' },
    component: () => import('@/views/agreement/agreementDetail'),
  },
  {
    path: '/missionDetailList',
    name: 'missionDetailList',
    meta: { keepAlive: true, name: '.' },
    component: () => import('@/views/mission/missionDetailList'),
  },
  {
    path: '/missionDetail',
    name: 'missionDetail',
    meta: { name: '.' },
    component: () => import('@/views/mission/missionDetail'),
  },
  {
    path: '/missionShareDetail',
    name: 'missionShareDetail',
    meta: { name: '分享任务详情' },
    component: () => import('@/views/mission/missionShareDetail'),
  },
  {
    path: '/signIn',
    name: 'signIn',
    meta: { name: '签到' },
    component: () => import('@/views/signIn'),
  },
  {
    path: '/commission',
    name: 'commission',
    meta: { name: '佣金' },
    // component: () => import('@/views/commission'),
    component: () => import('@/views/billing'),
  },
  {
    path: '/runningWater',
    name: 'runningWater',
    meta: { name: '流水' },
    component: () => import('@/views/runningWater'),
  },
  {
    path: '/cashOut',
    name: 'cashOut',
    meta: { name: '提现' },
    component: () => import('@/views/cashOut/index'),
  },
  {
    path: '/cashOutRecords',
    name: 'cashOutRecords',
    meta: { name: '提现记录' },
    component: () => import('@/views/cashOut/cashOutRecords'),
  },
  {
    path: '/subordinate',
    name: 'subordinate',
    meta: { name: '我的下级' },
    component: () => import('@/views/subordinate/index'),
  },
  {
    path: '/agreementList',
    name: 'agreementList',
    meta: { name: '我的合同' },
    component: () => import('@/views/agreement/agreementList'),
  },
  {
    path: '/about',
    name: 'about',
    meta: { name: '关于系统' },
    component: () => import('@/views/my/about'),
  },
  {
    //Transit.
    path: '/Transit',
    name: 'Transit',
    meta: { name: '跳转中...' },
    props: route => ({
      path: route.query.path,
      query: route.query.query,
    }),
    component: () => import('@/components/Transit'),
  },
];

const router = new VueRouter({
  routes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};


let shareMissionDetail = false;
let shareSchool = false;
router.beforeEach(async (to, from, next) => {
  if (to.path !== '/boot' && to.path !== '/') {
    if (to.path === '/missionDetail' || to.path === '/missionShareDetail') {
      shareMissionDetail = true;
    } else {
      if (shareMissionDetail || !shareSchool) {
        //todo: share school
        wxShareBySchool();

        shareMissionDetail = false;
        shareSchool = true;
      }
    }
  }

  document.title = to.meta.name;
  next();
});

export default router;
