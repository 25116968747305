<template>
  <div style="width: 100vw;height: 100vh;display: flex;justify-content: center;align-items: center;">
    <van-loading v-show="loading">登录中...</van-loading>
  </div>
</template>

<script>
import { getLocationSearch } from '@/util';
import { getTokenService } from '@/api/system';
import { SAVE_TOKEN, SAVE_SHOP_ID, SAVE_USER_ID } from '@/util/storage';
import { Dialog, Toast } from 'vant';
import flowChain from '@/util/flowChain';
import { VUE_APP_SHOP_URL } from '@/config'; // 获取用户当前所需要进行任务流程

export default {
  name: 'Boot',
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.wechatLogin();
  },
  methods: {
    /*
      http://school.ytjixiang.cn/auth/oauth/token?
      code=UfnJuMtpcdHL
      &shopId=sc-test
      &type=mobile
      &grant_type=shop
      &scope=server
    */
    wechatLogin() {
      let {
        code = '',
        shopId = '',
        type = 'mobile',
        grant_type = 'shop',
        scope = 'server',
      } = getLocationSearch() || {};

      let prams = { shopId, code, type, grant_type, scope };

      this.loading = true;
      getTokenService(prams).then(res => {
        this.loading = false;
        let { msg, access_token, user_id } = res.data || {};
        if (access_token) {
          SAVE_TOKEN(access_token);
          SAVE_SHOP_ID(shopId);
          SAVE_USER_ID(user_id);

          let locationSearch = getLocationSearch();
          let { taskId, userTaskId, contentId, path } = locationSearch;
          // 需要跳转的地址
          if (path) {
            this.$router.replace({
              path: `/${path}`,
              query: { taskId, userTaskId, contentId },
            });
          } else {
            // 获取用户当前所需要进行任务流程
            flowChain().then(location => {
              if (location)
                this.$router.replace(location);
              else
                this.$router.replace('/home');
            });
          }
        } else {
          let { taskId, userTaskId, path } = getLocationSearch();

          Toast('登录失败，请关闭后重新登录')

          // window.location.href = `${VUE_APP_SHOP_URL}/shop-mobile/oauth/login`
          // history.go(-1);
          // window.location.href = `${location.origin}/school-mobile/hCenter/login/${shopId}?shopId=${shopId}&taskId=${taskId}&userTaskId=${userTaskId}&path=${path}`;

          // Dialog.alert({
          //   message: `登录失败${msg || ''}`,
          //   confirmButtonText: '重试',
          // }).then(() => {
          //   // document.addEventListener('WeixinJSBridgeReady', function(){ WeixinJSBridge.call('closeWindow'); }, false);
          //   // WeixinJSBridge.call('closeWindow');
          //
          //   window.location.href = `${location.origin}/school-mobile/hCenter/login/${shopId}`;
          //   // this.wechatLogin();
          // });
        }
      });
    },
  },
};
</script>
