const TOKEN_NAME = 'pany_token';
const USER_ID_NAME = 'pany_userId';
const SHOP_ID_NAME = 'pany_shopId';
const LIKE_TASK_NAME = 'pany_like_task_history';

export const SAVE_TOKEN = token => {
  window.sessionStorage.setItem(TOKEN_NAME, token);
};
export const GET_TOKEN = () => {
  return window.sessionStorage.getItem(TOKEN_NAME);
};

export const SAVE_USER_ID = userId => {
  window.sessionStorage.setItem(USER_ID_NAME, userId);
};
export const GET_USER_ID = () => {
  return window.sessionStorage.getItem(USER_ID_NAME);
};

export const SAVE_SHOP_ID = shopId => {
  window.sessionStorage.setItem(SHOP_ID_NAME, shopId);
};
export const GET_SHOP_ID = () => {
  return window.sessionStorage.getItem(SHOP_ID_NAME);
};

export const GET_LIKE_TASK_HISTORY = () => {
  return JSON.parse(window.localStorage.getItem(LIKE_TASK_NAME) || '[]');
};
export const IS_LIKED_TASK = taskId => {
  let history = GET_LIKE_TASK_HISTORY();
  return history.indexOf(taskId) > -1;
};
export const SAVE_LIKE_TASK_HISTORY = taskId => {
  let history = GET_LIKE_TASK_HISTORY();
  !IS_LIKED_TASK(taskId) && history.push(taskId);
  window.localStorage.setItem(LIKE_TASK_NAME, JSON.stringify(history));
};

