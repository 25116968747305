import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/plugins/vant';
import '@/css/common.less';
import jweixin from '@/util/jweixin-module/lib/index.js';
import PageContainer from '@/components/PageContainer';
import VueClipboard from 'vue-clipboard2';

Vue.component(PageContainer.name, PageContainer);
Vue.use(VueClipboard);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
